@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Mohave);
@charset "UTF-8";


$lato: 'Lato', sans-serif;

$dull-black: #4A4A4A !default;
$black-base: #000000 !default;
$white-base: #ffffff !default;


$peter-river: #3498db;
$turquise: #1abc9c;
$green-sea: #16a085;
$wet-asphalt: #34495e;
$concrete: #95a5a6;
$clouds: #ecf0f1;
$silver: #bdc3c7;
$midnight-blue: #2c3e50;

/** Animation stuffs */
$default-animation-time: 0.2s !default;
$default-transition-time: 0.2s !default;
$default-shake-distance: 8px !default;
$default-short-slide-distance: 40px !default;
$default-cubic-in: cubic-bezier(0.5, 1.8, 0.9, 0.8);

html {
  background: #000;
}
body {
  margin: 0;
  padding: 0;
  background: #000;
  font-family: 'Lato', sans-serif;
  color: #333;
}

.video-responsive {
  margin: auto;
  position: relative;
  overflow: hidden;
}

.video-responsive iframe {

}

.app_main_wrapper {
	position: relative;
	width:100%;
	height:100%;
}

.global_pop_wrapper {
	position:fixed;
	z-index:99;
	top:0;
	left:0;
	margin:0;
	padding:0;
	width:100%;
	height:100%;
	background: #fff;
}

a {
	color: #31579d;
	text-decoration: none;
}

.main_header_link a {
	display: inline-block;
	width: 170px;
	cursor: pointer;
}

.menu_link {
	display: inline-block;
	width: 170px;
	cursor: pointer;
}

.activeMenuLink {
	color:#ee0064;
	display: inline-block;
	width: 170px;
	cursor: pointer;
}

.nonactiveHeaderLink {
    color: #fff;
}

.activeHeaderLink {
    color: #a9d800;
}

.country_select select{
	width: 300px;
}

